export default {
  code: `
// Starting the universe system
// Building the earth object
// Running the Simulation
const { 👩🏻, 👱🏻‍♂️} = 🌎;
👱🏻‍♂️.say('Will U Marry Me!💍');
👩🏻.response('Yes，❤️！');
Utils.marry(👰🏻‍♀️,🤵🏻);
Utils.generateInvitation(📜,🎉);
// Runing system check
// Execute the invitation`,
  executions: [
    {
      name: "Polishing the digital silverware...",
      time: "",
      duration: 0,
      visible: false,
    },
    {
      name: "Ironing the virtual tablecloth...",
      time: "",
      duration: 0,
      visible: false,
    },
    {
      name: "Chilling the pixelated champagne...",
      time: "",
      duration: 0,
      visible: false,
    },
    {
      name: "Arranging binary flower centerpieces...",
      time: "",
      duration: 0,
      visible: false,
    },
    {
      name: "Almost ready! Just teaching the AI host to mingle...🙂",
      time: "",
      duration: 0,
      visible: false,
    },
    {
      name: "just one second.. ☝️",
      time: "",
      duration: 0,
      visible: false,
    },
    {
      name: "ALmost Finished...",
      time: "",
      visible: false,
    },
  ],
  barrages: [
    "Wishing you a lifetime of love and laughter together! 💍❤️",
    "May your journey together be filled with endless joy! 🥂✨",
    "Cheers to love, laughter, and happily ever after! 🎉💖",
    "Here's to a beautiful future together! 🌟💑",
    "May your love story be as magical as today! ✨💍",
    "Wishing you a lifetime of shared dreams and happiness! 🥰🎊",
    "May your love grow stronger with each passing day! 💞💫",
    "Cheers to a lifetime of love, joy, and adventure! 🥂💖",
    "Wishing you a forever filled with love and joy! 💕✨",
    "Here's to a love that lasts a lifetime! 🥂❤️",
    "May your marriage be filled with endless love and laughter! 😄💍",
    "Wishing you both a lifetime of love, happiness, and togetherness! 💖🎉 ",
    "May your hearts always beat as one! 💕💑",
  ],
};
